import Vue from 'vue'
import Vuex from 'vuex'
import Cookies from 'js-cookie'
import {
  deepCopy
} from './deepcopy.js'
import createPersistedState from "vuex-persistedstate";
import {
  info
} from '@/api/member'

Vue.use(Vuex)
/**
 * 获得能通过第二个函数参数的值
 *
 * @param {Array} list
 * @param {Function} f
 * @return {*}
 */
export function find(list, f) {
  const {
    length
  } = list
  let index = 0
  let value
  while (++index < length) {
    value = list[index]
    if (f(value, index, list)) {
      return value
    }
  }
}

export default new Vuex.Store({
  state: {
    noticetitle: '', // views/home.vue  定义的全局提示信息标题
    notice: false, // views/home.vue  定义的全局提示信息状态  默认false 不显示 设置为ture时显示 

    currentArea: 0,
    cname: localStorage.getItem('cname') || '', //当前区县的名称
    //当前省
    sheng: parseInt(localStorage.getItem('sheng')) || '',
    //当前城市
    shi: parseInt(localStorage.getItem('shi')) || '',
    //当前区
    qu: '',
    currentCityArr: [],
    cityoptions: [],
    loginnav: [{
        id: 1,
        name: '数据中心',
        link: 'https://chinatupai.com',
        isSelect: true
      },
      {
        id: 2,
        name: '官方网站',
        link: 'https://chinatupai.com',
        isSelect: false
      },
      {
        id: 3,
        name: '业务合作',
        link: 'https://chinatupai.com',
        isSelect: false
      }, {
        id: 4,
        name: '关于我们',
        link: 'https://chinatupai.com',
        isSelect: false
      }

    ],
    loginMode: localStorage.getItem('loginMode') || 'telphone', //scan 扫码   telphone  电话号码  msg 信息验证码
    membersWechatScanId: localStorage.getItem('membersWechatScanId') || '',
    membersId: localStorage.getItem('membersId') || '',
    token: Cookies.get('token') || '',
    fromurl: localStorage.getItem('fromurl') || '',
    userinfo: Cookies.get('userinfo') === undefined ? '' : Cookies.get('userinfo'),
  },
  getters: {
    currentAreaList: function (state) {
      let currentshi;
      state.cityoptions.map(item => {
        if (state.sheng === item.id) {
          if (item.children != null) {
            currentshi = item.children.filter(i => {
              return i.id === state.shi
            })
          } else {
            return []
          }

        }
      })

      if (currentshi !== undefined) {
        state.currentCity = currentshi[0].children;
        //判断是否有选中的id 若为空初始化为第一个对象的id
        if (state.currentArea === 0) {
          state.currentArea = currentshi[0].children[0].id
        }
        return currentshi[0].children;
      }

    },
    token: function (state) {
      return state.token
    }
  },
  mutations: {
    setfromurl(state, v) {
      localStorage.setItem('fromurl', v)
      state.fromurl = v
    },

    userinfo(state, v) {
      // console.log('cunchu', JSON.stringify(v))
      state.userinfo = JSON.stringify(v)
      Cookies.set('userinfo', JSON.stringify(v), {
        path: '/',
        expires: 7,
        domain: process.env.VUE_APP_DOMAIN
      })
      // alert('userinfo')
    },

    setToken(state, v) {
      // alert('setToken')
      // localStorage.setItem('token', v)
      state.token = v
      //使用cookie 传递token
      Cookies.set('token', v, {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN,
        expires: 7
      })




      //返回登录的项目
      // window.location.href = JSON.parse(state.fromurl)

      const host = '"' + window.location.protocol + '//' + window.location.host + '"'

      if (host.toString() !== state.fromurl) {
        let url = JSON.parse(state.fromurl)
        // alert(url)
        window.location.href = url

       
      } else {
        // alert(process.env.VUE_APP_LOGIN_INDEX_DOMAIN)
 
        window.location.href = process.env.VUE_APP_LOGIN_INDEX_DOMAIN
     

      }


    },

    setMembersId(state, v) {
      localStorage.setItem('membersId', v)
      state.membersId = v
    },

    setMembersWechatScanId(state, v) {
      localStorage.setItem('membersWechatScanId', v)
      state.membersWechatScanId = v
    },

    setLoginMode(state, v) {
      localStorage.setItem('loginMode', v)
      state.loginMode = v
    },

    changeCname(state, v) {
      localStorage.setItem('cname', v)
      state.cname = v
    },

    currentArea(state, v) {
      state.currentArea = v
    },

    noticetitle(state, v) {
      state.noticetitle = v
    },
    notice(state, v) {
      state.notice = v
    },
    changeValue(state, obj) {

      state.currentCity = obj.currentCity;
      state.currentCityArr = obj.currentCityArr;
      state.cname = state.currentCity.label
    },
    changeSheng(state, v) {

      state.sheng = v;
    },
    //改变市
    changeShi(state, v) {
      state.shi = v
    },
    //改变区
    changeQu(state, v) {
      state.qu = v
    },
    //当前城市
    currentCity(state, v) {
      state.currentCity = v
    },
    //当前城市


  },
  actions: {
    //获取用户信息
    userinfo({
      commit
    }) {
      return new Promise(function (resole, reject) {
        info().then(res => {
          const userinfo = res.data
          commit('userinfo', userinfo)
          return resole(res.data)
        })
      })


    },

    //保存用户信息与设置token
    setUserinfo({
      commit
    }, token) {

      commit('setToken', token)
    },

    //更改当前城市
    currentCity({
      commit
    }, obj) {
      commit('currentCity', obj.children)
    },


    // //请求数据库
    // getcitylist({commit}){
    //   citylist().then(res=>{
    //     console.log("请求数据库",res.data)

    //    let data =  res.data
    //     commit('citylist',data)
    //   })
    // },

    changeValue({
      commit
    }, obj) {

      let currentCityValue = obj.currentCityValue
      // this.state.currentCityArr = currentCityValue
      let all = this.state.cityoptions
      // console.log(all)
      let c = [];
      // console.log(all)
      all.filter(item => {
        item.children.filter(i => {
          if (i.value == currentCityValue[1]) {
            c = deepCopy(i);
          }
          return i
        })
        return item;
      })
      let o = {
        currentCity: c,
        currentCityArr: currentCityValue
      }
      commit('changeValue', o)
    },
    //改变省
    changeSheng({
      commit
    }, v) {
      console.log('changesheng', v)
      localStorage.setItem('sheng', v)

      commit('changeSheng', v)
    },
    //改变市
    changeShi({
      commit
    }, v) {
      localStorage.setItem('shi', v)

      commit('changeShi', v)
    },
    //改变区
    changeQu({
      commit
    }, v) {
      commit('changeQu', v)
    }

  },
  modules: {},
  // plugins:[
  //   new createPersistedState({
  //     // 存储方式：localStorage、sessionStorage、cookies
  //     storage: window.sessionStorage,
  //     // 存储的 key 的key值
  // 		key: "store",
  // 		render(state) {
  //       // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
  //       // return   { ...state}
  //       // return {'sheng':state.sheng,'shi':state.shi}
  // 		}
  //   })
  // ]
})
import request from '@/utils/request'
import requestMock from '@/utils/requestMock'
import loginRequest from '@/utils/loginRequest'
import dataapiRequest from '@/utils/dataapiRequest'




//获取用户信息
export function info(params) {
  return request({
    url: '/members/info',
    method: 'post',
    params
  })

}




//登录导航
export function loginnav(params) {
  return loginRequest({
    url: "/login-navigationo/list",
    method: 'post',
    params
  })
}



//获取会员记录
export function membersbuyrecord(params) {
  return request({
    url: '/members/members-buy-record',
    method: 'post',
    params
  })

}


//保存用户信息
export function saveInfo(params) {
  return request({
    url: '/members/save-info',
    method: 'post',
    params
  })
}


//会员功能列表
export function memberfunctionlist(params) {
  return request({
    url: '/members/function-config2',
    method: 'post',
    params
  })
}




//会员购买记录
export function buyrecord(params) {
  return request({
    url: '/members/buy-record',
    method: 'post',
    params
  })
}


//会员退出
export function logout(params) {
  return request({
    url: '/members/logout',
    method: 'post',
    params
  })
}





//会员购买日期
export function vipdate(params) {
  return request({
    url: '/members/vip-config',
    method: 'post',
    params
  })
}

export function vipdate1(params) {
  return request({
    url: '/members/recharge-plan',
    method: 'post',
    params
  })
}




//更新手机号码
export function changeTelphone(params) {


  return request({
    url: '/members/change-phone',
    method: 'post',
    params
  })

}


//更改密码
export function changePassword(params) {

  return request({
    url: '/members/change-password',
    method: 'post',
    params
  })


}
import axios from 'axios'

import Cookies from 'js-cookie'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

const service = axios.create({
  baseURL:process.env.VUE_APP_BASE_API,
  timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }

)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    if(res.code === 401){
      store.commit('setToken', '')
      localStorage.setItem('token', '')
      Cookies.set('token', '', {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN
      })
      Cookies.remove('token')
      store.commit('userinfo', '')
      Cookies.set('userinfo', '', {
        path: '/',
        domain: process.env.VUE_APP_DOMAIN
      })
      Cookies.remove('userinfo')

      sessionStorage.clear();
      Cookies.set()
    
      return
    }

    if(res.code === 301){
      router.push('/login')
      return
    }

    if (res.code !== 1 && res.code !== 1001) {

      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }

    return res
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
